import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layouts"
import { Link, navigate } from "gatsby"
import Breadcrumb from "../components/slices/Breadcrumb"
import { buildBreadcrumb } from "../utils/buildBreadcrumb"
import { Hero } from "../components/hero"
import { IconShop } from "../components/svg"
import Img from "gatsby-image"

export const query = graphql`
  query ShopMyStyleQuery {
    allPrismicHomepage {
      edges {
        node {
          data {
            seotitle
            seodescription
            title {
              text
            }
          }
          id
          url
          type
        }
      }
    }
    allPrismicShopMyInstagram {
      edges {
        node {
          data {
            title {
              text
            }
            hero_text_colour
            feature_statement {
              text
            }
            hero_image {
              url
            }
          }
          uid
          id
        }
      }
    }
    allLikeToKnowItem {
      nodes {
        id
        hero_image {
          publicURL
        }
        hash
      }
    }
  }
`

const Image = (post, index) => {
  const { data, path } = post
  const url = `/${path}/${data.hash}`
  let state = {}

  function setState(url, e) {
    e.preventDefault()
    state = { offset: window.pageYOffset }
    navigate(url, { state })
  }

  return (
    <Link
      className={`item`}
      to={url}
      onClick={(e) => setState(url, e)}
      key={index}
    >
      {data.hero_image.publicURL && (
        <img src={data.hero_image.publicURL} alt="" loading="lazy" />
      )}

      <div className="shop-my-instagram__overlay">
        <div className="overlay-icon">
          <IconShop className="icon icon--shop" />
        </div>
      </div>
    </Link>
  )
}

export default ({ data, location }) => {
  const {
    allPrismicShopMyInstagram,
    allPrismicHomepage,
    allLikeToKnowItem,
  } = data
  const pageData = allPrismicShopMyInstagram.edges[0].node
  const homeContent = allPrismicHomepage.edges[0].node

  const instagramPosts = allLikeToKnowItem.nodes
  const thisBreadcrumb = buildBreadcrumb([homeContent, pageData])

  useEffect(() => {
    if (location.state && location.state.state) {
      window.scrollTo(0, location.state.state.offset)
    }
  }, [location])

  return (
    <Layout>
      <Helmet>
        <title>
          {pageData.data.seotitle
            ? pageData.data.seotitle
            : homeContent.seotitle}
        </title>
        <meta
          name="description"
          content={
            pageData.data.seodescription
              ? pageData.data.seodescription
              : homeContent.seodescription
          }
        />
      </Helmet>
      <Hero className="compact" props={pageData.data} />
      <div className="container">
        <Breadcrumb className="border-bottom" data={thisBreadcrumb} />
        <div className="shop-my-instagram-content">
          <p className="shop-my-instagram-quote">
            "{pageData.data.feature_statement.text}"
          </p>
          <div className="shop-my-instagram-divider" />
        </div>
      </div>

      <div className="container">
        <div className="instagram-holder">
          {instagramPosts.map((chunk, index) => {
            return <Image data={chunk} path={pageData.uid} key={index} />
          })}
        </div>
      </div>
    </Layout>
  )
}
